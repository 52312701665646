import React, { lazy } from "react";
import { Route, Routes } from "react-router";

const Home = lazy(() => import("../pages/home"));

const Navigation = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
  );
};

export default Navigation;
