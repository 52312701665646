import React, { Suspense } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import Navigation from "./navigation";
import DefaultLoader from "src/components/loaders/DefaultLoader";

const theme = createTheme({
  palette: {
    primary: {
      main: "#05132D",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense
        fallback={
          <div className="flex flex-col h-screen justify-center items-center">
            <DefaultLoader />
          </div>
        }
      >
        <Navigation />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
